.horizontal-slider .example-track {
  top: 20px;
  height: 8px;
}
.example-track-0 {
  background-color: rgb(229 231 235);
  border-radius: 10px 0 0 10px;
}
.example-track-1 {
  background-color: #1570ef;
  border-radius: 10px;
}
.example-track-2 {
  background-color: rgb(229 231 235);
  border-radius: 0 10px 10px 0;
}

.example-thumb-0,
.example-thumb-1 {
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #1570ef;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
