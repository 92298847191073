.category {
  padding: 8px;
  outline: none;
  height: 32px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  transition: background-color 300ms ease-in-out 0s;
  cursor: pointer;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
  /* width: auto !important;
     */
  width: fit-content;
}

.react-multi-carousel-item{
  padding-right: 0px !important;
}

.activeSubCategory {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: rgb(34, 41, 57);
  cursor: pointer;
  transition: all 300ms ease-out 0s;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(34, 41, 57, 0.15) 0px 4px 20px 0px;
  position: relative;
  border: 1px solid rgb(186, 177, 255);
}

.activeSubCategory:hover {
  background-color: #fff;
  /* color: rgb(255, 255, 255); */
}

.subCategory {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: rgb(34, 41, 57);
  cursor: pointer;
  transition: all 300ms ease-out 0s;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: #e1e1e1;
  box-shadow: unset;
  position: relative;
  border: 1px solid #b3b3b3;
}

.breadcrumb {
  padding: 2px 2px;
  list-style: none;
  /* background-color: #eee; */
}

.breadcrumb li {
  display: inline;
  font-size: 18px;
}

.breadcrumb li+li:before {
  padding: 1px;
  color: rgb(107 114 128);
  content: "/\00a0";
}

.breadcrumb p {
  display: inline;
  cursor: pointer;
}

.breadcrumb li p {
  color: #0275d8;
  text-decoration: none;
}

.breadcrumb li p:hover {
  /* color: #01447e; */
  text-decoration: underline;
}

.margin-top-px {
  margin-top: 12px;
}

.margin-bottom-px {
  margin-bottom: 6px;
}

.react-multi-carousel-item{
  width: max-content !important;
  padding-right: 18px;
}