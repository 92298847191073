.custom-rounded-lg {
    border-radius: 0.8rem;
}

.productImage {
    height: 250px;
    object-fit: cover;
}

.alignTop {
    /* align-self: baseline; */
    align-self: flex-start;
}

.custom-no-data-overlay {
    position: absolute;
    top: 100px;
    left: 100px;
    z-index: 10000000000000;
    background-color: red;
    padding: 10px;
}

.css-axafay-MuiDataGrid-virtualScroller {
    min-height: 120px;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fade-in 0.2s;
}

.loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 0.7s linear infinite;
    animation: spinner 0.7s linear infinite;
}


/* settings page styles */
.active-plan {
    box-shadow: 0 8px 12px -2px hsl(214.79deg 87.25% 50.78% / 23%), 0 4px 6px -1px rgb(20 112 239 / 40%);
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.MuiPagination-ul {
    justify-content: center;
}