@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .btn-primary {
    @apply px-5 mt-2 py-1.5 text-sm bg-primary text-white font-[500] rounded-lg border-[1px] border-primary;
  }

  .btn-secondary {
    @apply px-5 mt-2 py-1.5 text-sm text-gray-500 font-[500] rounded-lg border-[1px] border-gray-400;
  }

  .title {
    @apply text-2xl font-[600] text-[#101828] ease-in duration-300;
  }
  .subtitle {
    @apply text-gray-500;
  }
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Custom scrollbar */
.scroll::-webkit-scrollbar {
  width: 0.3em;
}

.scroll::-webkit-scrollbar-track {
  background-color: #f3f4f6;
  border-radius: 70vw;
  cursor: pointer;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #515961;
  border-radius: 70vw;
  cursor: pointer;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background: #6c757d;
}

.filterScroll::-webkit-scrollbar {
  width: 0.2em;
}

.filterScroll::-webkit-scrollbar-track {
  background-color: #f3f4f6;
  border-radius: 70vw;
  cursor: pointer;
}

.filterScroll::-webkit-scrollbar-thumb {
  background-color: #a7b3be;
  border-radius: 70vw;
  cursor: pointer;
}

/* Custom Range bar */
input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.2s;
}